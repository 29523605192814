// Data
import { useMemo } from "react"
import MaterialsData from "../asset/data/materials.json"
import RecipesData from "../asset/data/recipes.json"

// Materials Interface
export interface RawMaterial {
    name: string
    weight: number
    sell?: Sellable
}

export interface Material {
    name: string
    weight: number
    crafting: RawRecipe[]
    usage: RawRecipe[]
    sell?: Sellable
}

export interface Sellable {
    value: number
    location: string
}

// Recipes Interface
export interface RawRecipe {
    name: string
    cost: number
    secret: boolean
    input: RawCraftingItem[]
    output: RawCraftingItem[]
}

export interface Recipe {
    name: string
    cost: number
    secret: boolean
    input: CraftingItem[]
    output: CraftingItem[]
}

export interface RawCraftingItem {
    name: string
    count: number
}

export interface CraftingItem {
    material: RawMaterial
    count: number
}

const RawMaterials: RawMaterial[] = MaterialsData as RawMaterial[]
const RawRecipes: RawRecipe[] = RecipesData as RawRecipe[]

export function useData() {

    // Materials
    const materials: Material[] = useMemo(() => {
        return RawMaterials.map<Material>(raw => {
            const dependencyRecipe = RawRecipes.filter(rec => rec.input.filter(mat => mat.name === raw.name).length > 0 || rec.output.filter(mat => mat.name === raw.name).length > 0)

            if (dependencyRecipe.length === 0) {
                console.warn(`${raw.name} has not dependency recipes!`)
            }

            return {
                ...raw,
                crafting: dependencyRecipe.filter(rec => rec.output.filter(mat => mat.name === raw.name).length > 0),
                usage: dependencyRecipe.filter(rec => rec.input.filter(mat => mat.name === raw.name).length > 0)
            }
        })
    }, [])

    const sortedMaterials = useMemo(() => {
        return materials.sort((a, b) => a.name < b.name ? -1 : 1)
    }, [materials])

    const farmableMaterials: Material[] = useMemo(() => {
        return sortedMaterials.filter((mat) => mat.sell)
    }, [sortedMaterials])

    const findMaterialByName = (name: string): Material | undefined => sortedMaterials.find(mat => mat.name === name)
    
    const findFarmableMaterialByName = (name: string): Material | undefined => farmableMaterials.find(mat => mat.name === name)

    // Recipes
    const recipes: Recipe[] = useMemo(() => {
        return RawRecipes.map<Recipe>(raw => ({
            ...raw,
            input: raw.input.map<CraftingItem>(rawInput => {
                const mat = RawMaterials.find((rawMat) => rawMat.name === rawInput.name)

                if (!mat) throw new Error(`Missing Material: ${rawInput.name}`)

                return {
                    material: mat,
                    count: rawInput.count
                }
            }),
            output: raw.input.map<CraftingItem>(rawInput => {
                const mat = RawMaterials.find((rawMat) => rawMat.name === rawInput.name)

                if (!mat) throw new Error(`Missing Material: ${rawInput.name}`)

                return {
                    material: mat,
                    count: rawInput.count
                }
            })
        }))
    }, [])

    const sortedRecipes = useMemo(() => {
        return recipes.sort((a, b) => a.name < b.name ? -1 : 1)
    }, [recipes])

    return {
        materials: {
            sorted: sortedMaterials,
            farmable: farmableMaterials,
            utils: {
                findMaterialByName,
                findFarmableMaterialByName
            }
        },
        recipes: {
            sorted: sortedRecipes
        }
    }
}
import { Storage } from "./hooks/@class/Inventory"
import { Material } from "./hooks/dataHook"
import { MaterialQuantities } from "./hooks/routeCalculatorHooks"

export function formatNumberWithDots(value: number): string {
    if (value || value === 0) 
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
 
    return ""
}

export function totalWeight(materials: MaterialQuantities[]): number {
    let weight = 0
    materials.forEach(mat => weight += mat.material.weight * mat.qty)

    return weight
}

export function bestInventoryRatio(materialsRatio: MaterialQuantities[], playerStorage: Storage, truckStorage: Storage): number {
    /** 
     * return the number of crafting you can do in one route and fill playerStorage and truckStorage with
     * the number of material to perform the craftings
    */

    const weightPerCrafting = totalWeight(materialsRatio)
    const craftingsPerRoute = Math.trunc((playerStorage.maxWeight + truckStorage.maxWeight) / weightPerCrafting)
    const sortedMatRatio = materialsRatio.sort((a, b) => a.material.weight - b.material.weight)
    
    let toRun = true
    let craftingOffset = 0
    while (toRun) {
        // Reset Storage
        playerStorage.clear()
        truckStorage.clear()
        
        let craftingCount = craftingsPerRoute - craftingOffset
        try {
            sortedMatRatio.forEach(matqty => {
                const matWeightPerRoute = matqty.material.weight * matqty.qty * craftingCount

                if (truckStorage.freeSpace >= matWeightPerRoute) {
                    // if there are space for all qty of one material

                    // add material to truck
                    truckStorage.addMaterials(matqty.material, matqty.qty * craftingCount)
                } else {
                    let materialCount = matqty.qty * craftingCount
                    if (truckStorage.freeSpace >= matqty.material.weight) {
                        // if space for 1+ materials

                        // remove item that can be in truck
                        let materialIntoTruck = Math.trunc(truckStorage.freeSpace / matqty.material.weight)
                        materialCount -= materialIntoTruck

                        // add material to truck
                        truckStorage.addMaterials(matqty.material, materialIntoTruck)
                    }
                    
                    if (materialCount * matqty.material.weight > playerStorage.freeSpace) {
                        // if no sufficent free space throw error and retry and decrease crating count
                        
                        throw new Error()
                    } 
                        
                    // add remaining materials to player inventory
                    playerStorage.addMaterials(matqty.material, materialCount)
                    toRun = false
                }
            })
        } catch (e) {
            craftingOffset++

            if (craftingOffset > 2) 
                toRun = false
        }
    }

    return craftingsPerRoute - craftingOffset
}
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap'

import TTLogo from "../asset/images/tt_icon.png"

export function Header() {
    return <Navbar>
        <Container>
            <Navbar.Brand href="https://wiki.tycoon.community/">
                <Image src={TTLogo} height={40}/>
            </Navbar.Brand>
            <Navbar.Collapse>
                <Nav>
                    <LinkContainer to={"/materials"}>
                        <Nav.Link>Materials</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={"/recipes"}>
                        <Nav.Link>Recipes</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={"/routes"}>
                        <Nav.Link>Routes</Nav.Link>
                    </LinkContainer>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
}
import { Accordion, Badge, Col, Container, ListGroup, Row } from "react-bootstrap"
import { formatNumberWithDots } from "../utils"

// hooks
import { useData } from "../hooks/dataHook"

export function Recipes() {

    const { recipes } = useData()

    return <Container className="mt-4">
        <Accordion>
            { recipes.sorted.map((recipe, idx) => <Accordion.Item key={idx} eventKey={idx.toString()}>
                <Accordion.Header>
                    <div className="w-100 mx-2 d-flex justify-content-between align-items-center">
                            <div className="w-100 mx-2 d-flex justify-content-start align-items-center">
                                {recipe.name}
                                { recipe.secret ? <Badge bg="warning text-bg-warning mx-2">Secret</Badge> : null }
                            </div>
                        { recipe.cost > 0 ? <Badge bg="danger">$ {formatNumberWithDots(recipe.cost)}</Badge> : <Badge bg="success">FREE</Badge> }
                    </div>
                </Accordion.Header>
                <Accordion.Body className="py-3 px-5">
                    <Row>
                        <Col className="md-6">
                            <ListGroup className="ms-2">
                                <ListGroup.Item className="bg-light-subtle fw-bold">Input Materials</ListGroup.Item>
                                { recipe.input.map((item, idx) => <ListGroup.Item key={idx} className="d-flex justify-content-between align-items-center">
                                        <a href={`/materials#${item.material.name}`}>{item.material.name}</a>
                                    <Badge bg="secondary">{item.count}</Badge>
                                </ListGroup.Item>) }
                            </ListGroup>
                        </Col>
                        <Col>
                            <ListGroup className="ms-2">
                                <ListGroup.Item className="bg-light-subtle fw-bold">Output Materials</ListGroup.Item>
                                { recipe.output.map((item, idx) => <ListGroup.Item key={idx} className="d-flex justify-content-between align-items-center">
                                        <a href={`/materials#${item.material.name}`}>{item.material.name}</a>
                                    <Badge bg="secondary">{item.count}</Badge>
                                </ListGroup.Item>) }
                            </ListGroup>
                        </Col>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>) }
        </Accordion>
    </Container>
}
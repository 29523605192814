import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from "react";
import { Accordion, Badge, Button, Card, Col, Container, Form, InputGroup, ListGroup, OverlayTrigger, Row, Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { formatNumberWithDots } from "../utils";

// components
import { Dialog } from "../components/dialog";

// hooks
import { Material, useData } from "../hooks/dataHook";
import { ComputedRoute, Route, useRouteCalculator } from "../hooks/routeCalculatorHooks";

// style
import "../styles/form.css"

// icons
import { ReactComponent as RouteIcon } from "../asset/icons/route.svg"
import { ReactComponent as TruckIcon } from "../asset/icons/truck.svg"
import { ReactComponent as PersonIcon } from "../asset/icons/person.svg"
import { ReactComponent as LocationIcon } from "../asset/icons/location.svg"

export interface FormData {
    route: number
    money: number
    playerSpace: number
    truckSpace: number
    showExtraMat: boolean
}

export function TruckRoute() {

    const location = useLocation()
    const { materials } = useData()
    const { compute, computeItem, computeMaterials } = useRouteCalculator()
    
    // state
    const [ disableForm, setDisableForm ] = useState<boolean>(false)
    const [ showDialog, setShowDialog ] = useState<boolean>(false)
    const [ multipleRecipes, setMutipleRecipes ] = useState<[Material, number][]>([])
    const [ computedRoutes, setComputedRoutes ] = useState<ComputedRoute | undefined>()

    const defaultFormData: FormData = {
        route: 0,
        money: 0,
        playerSpace: 0,
        truckSpace: 0,
        showExtraMat: true
    }
    const [formData, setFormData] = useState<FormData>(localStorage.getItem("routeForm") ? JSON.parse(localStorage.getItem("routeForm") as string) : defaultFormData)

    useEffect(() => {
        if (location.state) {
            const index = materials.farmable.findIndex((mat) => mat.name === location.state)

            if (index) {
                setFormData(data => ({
                    ...data,
                    route: index
                }))
            }
        }
    }, [])

    useEffect(() => {
        localStorage.setItem("routeForm", JSON.stringify(formData))
    }, [formData])

    useEffect(() => {
        if (multipleRecipes.length !== 0) {
            checkMultiRecipes()

            if (!showDialog) 
                setShowDialog(true)
        }
    }, [multipleRecipes])

    const handleSubmit = async (evt: FormEvent) => {
        setDisableForm(true)
        setMutipleRecipes([])

        if (checkMultiRecipes()) {
            setDisableForm(false)
            computeRoutes()
        }

        evt.preventDefault()
        evt.stopPropagation()
    }

    const computeRoutes = () => {
        const forcedRecipes: { [key: string]: string } = {}

        multipleRecipes.forEach(item => {
            forcedRecipes[item[0].name] = item[0].crafting[item[1]].name
        })

        setComputedRoutes(compute(formData, forcedRecipes))
        setDisableForm(false)
    }

    const checkMultiRecipes = (): boolean => {
        const targetMaterial = materials.farmable[formData.route]
        const forcedRecipes: { [key: string]: string } = {}

        multipleRecipes.forEach(item => {
            forcedRecipes[item[0].name] = item[0].crafting[item[1]].name
        })

        let material: Material | undefined = targetMaterial
        while (material !== undefined) {
            material = computeMaterials(targetMaterial, forcedRecipes)

            if (material !== undefined) {
                const defaultIdx = 0
                setMutipleRecipes([...multipleRecipes, [material, defaultIdx]])
                setShowDialog(true)

                return false
            }
        }

        return !showDialog
    }

    const validateMaterial = (evt: ChangeEvent<HTMLSelectElement>) => {
        const index = Number(evt.target.value)

        try {
            setFormData(formData => ({
                ...formData,
                route: index
            } satisfies FormData))
        } catch { }
    }

    const validateMoney = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value.replaceAll(".", "")
        
        try {
            setFormData(formData => ({
                ...formData,
                money: Number(value)
            } satisfies FormData))
        } catch { }
    }

    const validatePlayerSpace = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value.replaceAll(".", "")
        
        try {
            setFormData(formData => ({
                ...formData,
                playerSpace: Number(value)
            } satisfies FormData))
        } catch { }
    }


    const validateTruckSpace = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value.replaceAll(".", "")
        
        try {
            setFormData(formData => ({
                ...formData,
                truckSpace: Number(value)
            } satisfies FormData))
        } catch { }
    }

    const computeExtraProfit = useMemo(() => {
        let total = 0
        computedRoutes?.extraMaterials.storage.forEach(inst => {
            const findMaterial = materials.utils.findFarmableMaterialByName(inst.material.name)
            let subProcessing = undefined
                    
            /*if (!findMaterial) {
                subProcessing = computeItem(material.name, material.qty, formData.truckSpace, formData.playerSpace)
                if (subProcessing) total += subProcessing[1]

            }else if (findMaterial.sell) {
                total += findMaterial.sell.value * material.qty
            }*/
            
            if (findMaterial?.sell) {
                total += findMaterial.sell.value * inst.qty
            }
        })

        return total
    }, [computedRoutes, materials.farmable])

    return <>
        <Dialog title="Detected multiples recipes" show={showDialog} onConfirm={() => {
            setShowDialog(false)
            computeRoutes()
        }} onClose={() => {
            setShowDialog(false)
            setDisableForm(false)
        }}>
            <Stack gap={4}>
                { multipleRecipes.map((it, idx) => <Stack key={idx} gap={2}>
                    <span>Which crafting want you use for <span className="fw-bold">{ it[0].name }</span>:</span>
                    <Form.Group>
                        <Form.Select className="ms-2" onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
                            const newMultRec = multipleRecipes.map<[Material, number]>((it, i) => {
                                if (i !== idx) return it

                                return [ it[0], Number(evt.target.value) ]
                            })

                            setMutipleRecipes(newMultRec)
                        }} style={{ width: "95%" }} value={it[1]}>
                            { it[0].crafting.map((rec, idx) => <option key={idx} value={idx}>{rec.name}</option>) }
                        </Form.Select>
                    </Form.Group>
                </Stack>) }
            </Stack>
        </Dialog>
        <Container>
            <Container className="mt-4 p-4 border shadow rounded">
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-2">
                        <Form.Group as={Col}>
                            <Form.Label>Select Material</Form.Label>
                            <Form.Select className="ms-2" required
                                onChange={validateMaterial}
                                value={formData.route}> 
                                { materials.farmable.map((mat, idx) => <option key={idx} value={idx}>{mat.name}</option>) }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Target Money</Form.Label>
                            <InputGroup className="mb-3 ms-2">
                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                <Form.Control required placeholder="0" type="string"
                                    onChange={validateMoney}
                                    value={formatNumberWithDots(formData.money)}/>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2">
                        <Form.Group as={Col}>
                            <Form.Label>Player Inventory Space</Form.Label>
                                <InputGroup className="mb-3 ms-2">
                                    <InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
                                    <Form.Control required placeholder="0" type="string"
                                        onChange={validatePlayerSpace}
                                        value={formatNumberWithDots(formData.playerSpace)}/>
                                </InputGroup>
                            </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Truck Inventory Space</Form.Label>
                            <InputGroup className="mb-3 ms-2">
                                <InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
                                <Form.Control required placeholder="0" type="string"
                                    onChange={validateTruckSpace}
                                    value={formatNumberWithDots(formData.truckSpace)}/>
                            </InputGroup>
                        </Form.Group>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Button className="col-md-4 shadow-sm" type="submit" disabled={disableForm}>Compute</Button>
                        </Col>
                        <Col>
                            <Form.Check reverse label="Show Extra Materials" type="checkbox" id="reverse-checkbox-1" 
                                onChange={(evt: ChangeEvent<HTMLInputElement>) => setFormData(formData => ({
                                    ...formData,
                                    showExtraMat: evt.target.checked
                                } satisfies FormData))}
                                checked={formData.showExtraMat}/>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <Container className="mt-5">
                { computedRoutes && computedRoutes.routes.length > 0 ? <ListGroup>
                    <ListGroup.Item className="bg-light-subtle fw-bold d-flex justify-content-between align-items-center">
                        Routes Needed
                        <div className="d-flex justify-content-center align-items-center mx-2" style={{ gap: 8 }}>
                            <Badge bg="success">
                                { `$ ${formatNumberWithDots(computedRoutes.routes.reduce((acc, curr) => ({
                                    ...acc,
                                    cost: acc.cost + curr.cost
                                })).cost)}` }
                            </Badge>
                            <Badge bg="primary text-bg-primary" style={{ minWidth: 56 }}>
                                <div className="d-flex justify-content-center align-items-center"  style={{ gap: 8 }}>
                                    <RouteIcon style={{ height: 12, fill: "var(--bs-body-color)" }}/>
                                    <div>
                                        { computedRoutes.routes.reduce((acc, prev) => ({
                                            ...prev,
                                            count: acc.count + prev.count
                                        })).count }
                                    </div>
                                </div>
                            </Badge>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="p-0 border-0">
                        <Accordion>
                            { computedRoutes.routes.map((route, idx) => <Accordion.Item className={idx === 0 ? "rounded-0" : ""} key={idx} eventKey={idx.toString()}>
                                    <Accordion.Header>
                                        <div className="w-100 mx-2 d-flex justify-content-between align-items-center">
                                            {route.name}
                                            <div className="d-flex justify-content-center align-items-center mx-2" style={{ gap: 8 }}>
                                                { route.cost > 0 ? <Badge bg="success">
                                                    {`$ ${formatNumberWithDots(route.cost)}`}
                                                </Badge> : route.cost < 0 ? <Badge bg="danger">
                                                    {`$ ${formatNumberWithDots(Math.abs(route.cost))}`}
                                                </Badge> : null }
                                                <Badge bg="primary text-bg-primary" style={{ minWidth: 56 }}>
                                                    <div className="d-flex justify-content-center align-items-center"  style={{ gap: 8 }}>
                                                        <RouteIcon style={{ height: 12, fill: "var(--bs-body-color)" }}/>
                                                        <div>{ route.count }</div>
                                                    </div>
                                                </Badge>
                                            </div>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <ListGroup className="ms-2 mt-4">
                                                    <ListGroup.Item className="bg-light-subtle fw-bold">Required Materials</ListGroup.Item>
                                                    { route.requireMaterials.map((matqty, idx) => {
                                                        const truckRatio = route.ratio.truck.find(inv => inv.material.name === matqty.material.name)
                                                        const playerRatio = route.ratio.player.find(inv => inv.material.name === matqty.material.name)

                                                        const truckQty = truckRatio ? truckRatio.qty : 0
                                                        const playerQty = playerRatio ? playerRatio.qty : 0

                                                        return <ListGroup.Item key={idx} className="d-flex justify-content-between align-items-center">
                                                            <a href={`/materials#${matqty.material.name}`}>{matqty.material.name}</a>
                                                            <Badge bg="secondary">{(truckQty + playerQty) * route.count}</Badge>
                                                        </ListGroup.Item>}
                                                    ) }
                                                </ListGroup>
                                            </Col>
                                            <Col>
                                                <ListGroup className="ms-2 mt-4">
                                                    <ListGroup.Item className="bg-light-subtle fw-bold">Materials Per Route</ListGroup.Item>
                                                    { route.requireMaterials.map((matqty, idx) => {
                                                            const truckRatio = route.ratio.truck.find(inv => inv.material.name === matqty.material.name)
                                                            const playerRatio = route.ratio.player.find(inv => inv.material.name === matqty.material.name)

                                                            return <ListGroup.Item key={idx} className="d-flex justify-content-between align-items-center">
                                                                <a href={`/materials#${matqty.material.name}`}>{matqty.material.name}</a>
                                                                <div className="d-flex justify-content-center align-items-center" style={{ gap: 12 }}>
                                                                    <Badge bg="warning text-bg-warning" style={{ minWidth: 48 }}>
                                                                        <div className="d-flex justify-content-center align-items-center"  style={{ gap: 6 }}>
                                                                            <TruckIcon style={{ height: 12, fill: "var(--bs-body-bg)" }}/>
                                                                            <div>{ truckRatio ? truckRatio.qty : "0" }</div>
                                                                        </div>
                                                                    </Badge>
                                                                    <Badge bg="info text-bg-info" style={{ minWidth: 48 }}>
                                                                        <div className="d-flex justify-content-center align-items-center" style={{ gap: 6 }}>
                                                                            <PersonIcon style={{ height: 12, fill: "var(--bs-body-bg)" }}/>
                                                                            <div>{ playerRatio ? playerRatio.qty : "0" }</div>
                                                                        </div>
                                                                    </Badge>
                                                                </div>
                                                            </ListGroup.Item>
                                                        }) }
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>)}
                        </Accordion>
                    </ListGroup.Item>
                </ListGroup> : null }
            </Container>
            { formData.showExtraMat && computedRoutes ? <Container className="mt-5">
                { computedRoutes.extraMaterials.storage.length > 0 ? <ListGroup>
                    <ListGroup.Item className="bg-light-subtle fw-bold d-flex justify-content-between align-items-center">
                        Extra Materials
                        { computeExtraProfit ? <Badge bg="success">
                            { `$ ${formatNumberWithDots(computeExtraProfit)}` }
                        </Badge> : null }
                    </ListGroup.Item>
                    { computedRoutes.extraMaterials.storage.sort((a, b) => b.qty - a.qty).map((inst, idx) => {
                        const findMaterial = materials.utils.findFarmableMaterialByName(inst.material.name)
                        let processing: Route | undefined = computeItem(inst.material.name, inst.qty, formData.truckSpace, formData.playerSpace)

                        return <ListGroup.Item key={idx}>
                            <div className="mx-3 w-auto d-flex justify-content-between align-items-center">
                                <div className="d-flex justify-content-center align-items-center" style={{ gap: 12 }}>
                                    { findMaterial?.sell ? <OverlayTrigger placement="top" overlay={<Card>
                                            <Card.Body className="pb-2">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Card.Title>{inst.material.name}</Card.Title>
                                                    { processing ? <Badge bg="primary" className="ms-3">
                                                        <div className="d-flex justify-content-center align-items-center"  style={{ gap: 6 }}>
                                                            <RouteIcon style={{ height: 12, fill: "var(--bs-body-color)" }}/>
                                                            <div>{ processing.count }</div>
                                                        </div>
                                                    </Badge> : null }
                                                </div>
                                            </Card.Body>
                                            <ListGroup className="mx-0 rounded-0">
                                                <ListGroup.Item className="border-0 d-flex justify-content-left align-items-center">
                                                    <span className="text-muted">Selling Point:</span>
                                                    <Badge bg="warning" text="dark" className="ms-3">
                                                        <div className="d-flex justify-content-center align-items-center"  style={{ gap: 6 }}>
                                                            <LocationIcon style={{ height: 12, fill: "var(--bs-body-bg)" }} />
                                                            {findMaterial.sell.location}
                                                        </div>
                                                    </Badge>
                                                </ListGroup.Item>
                                                { processing ? <>
                                                    <ListGroup.Item className="border-0 d-flex justify-content-left align-items-center">
                                                        <span className="text-muted">Max Subdivsion:</span>
                                                        <div className="ms-3 d-flex justify-content-center align-items-center" style={{ gap: 6 }}>
                                                            <Badge bg="warning text-bg-warning" style={{ minWidth: 48 }}>
                                                                <div className="d-flex justify-content-center align-items-center"  style={{ gap: 6 }}>
                                                                    <TruckIcon style={{ height: 12, fill: "var(--bs-body-bg)" }}/>
                                                                    <div>{ processing.ratio.truck[0].qty }</div>
                                                                </div>
                                                            </Badge>
                                                            <Badge bg="info text-bg-info" style={{ minWidth: 48 }}>
                                                                <div className="d-flex justify-content-center align-items-center" style={{ gap: 6 }}>
                                                                    <PersonIcon style={{ height: 12, fill: "var(--bs-body-bg)" }}/>
                                                                    <div>{ processing.ratio.player[0].qty }</div>
                                                                </div>
                                                            </Badge>
                                                        </div>
                                                    </ListGroup.Item>
                                                </> : null }
                                            </ListGroup>
                                        </Card>}>
                                        <a href={`/materials#${inst.material.name}`}>{inst.material.name}</a>
                                    </OverlayTrigger> : <a href={`/materials#${inst.material.name}`}>{inst.material.name}</a> }
                                    { findMaterial?.sell ? <div className="d-flex justify-content-center align-items-center">
                                        <Badge bg="success" style={{ minWidth: 56 }}>
                                            { `$ ${formatNumberWithDots(inst.qty * findMaterial.sell.value)}` }
                                        </Badge>
                                    </div> : null }
                                </div>
                                <Badge bg="secondary" style={{ minWidth: 56 }}>
                                    { inst.qty }
                                </Badge>
                            </div>
                        </ListGroup.Item>
                    }) }
                </ListGroup> : null }
            </Container> : null }
        </Container>
    </>
}
import { Button, Modal } from "react-bootstrap";

export function Dialog(props: { title: string, children: JSX.Element, show: boolean, onConfirm?: () => void, onClose?: () => void }) {

    const { title, children, show, onConfirm, onClose  } = props

    return <Modal show={show} backdrop="static" keyboard={false}>
        <Modal.Header>
            <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { children }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>Close</Button>
            <Button variant="primary" onClick={onConfirm}>Confirm</Button>
        </Modal.Footer>
    </Modal>
}
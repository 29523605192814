import { Route } from "react-router-dom"
import { FormData } from "../pages/truckroute"
import { Material, RawRecipe, useData } from "./dataHook"
import { InventoryInstance, Storage } from "./@class/Inventory"
import { bestInventoryRatio } from "../utils"

export interface Route {
    name: string
    count: number
    cost: number
    requireMaterials: MaterialQuantities[]
    ratio: InventoryRatio
}

export interface MaterialQuantities {
    material: Material
    qty: number
}

export interface InventoryRatio {
    player: InventoryInstance[]
    truck: InventoryInstance[]
}

export interface ComputedRoute {
    routes: Route[]
    extraMaterials: Storage
}

export function useRouteCalculator() {

    const { materials, recipes } = useData()

    const findRecipe = (mat: Material, forcedRecipes: { [key: string]: string }): RawRecipe | undefined => {
        if (mat.name in forcedRecipes)
            return mat.crafting.find(rec => rec.name === forcedRecipes[mat.name])

        if (mat.crafting.length === 1) 
            return mat.crafting[0]

        return undefined
    }

    const mergeEqualRoute = (routes: Route[]): Route[] => {
        const output: Route[] = []

        routes.forEach(route => {
            const sameRoute = output.find(it => it.name === route.name)
            
            if (sameRoute) {
                sameRoute.count += route.count
                sameRoute.cost += route.cost
                sameRoute.requireMaterials.forEach(mat => {
                    const sameMat = route.requireMaterials.find(m => m.material.name === mat.material.name)
                    
                    if (sameMat) {
                        mat.qty += sameMat.qty
                    }
                })
            }else output.push(route)
        })

        return output
    }

    const recursiveCompute = (material: Material, needed: number, data: FormData, forcedRecipes: { [key: string]: string }, result: ComputedRoute): void => {
        const recipe = findRecipe(material, forcedRecipes)
        
        if (recipe) {
            const materialFromStorage = result.extraMaterials.peekMaterials(material, needed)
            const qtyPerCraft = recipe.output.find((item) => item.name === material.name)?.count as number
            const craftingNeeded = Math.ceil((needed - materialFromStorage) / qtyPerCraft)
            
            if (craftingNeeded > 0) {
                // Init Input and Output matqty
                const inputMatqty: MaterialQuantities[] = recipe.input.map<MaterialQuantities>(item => {
                    const mat = materials.utils.findMaterialByName(item.name)
                    if (mat === undefined) {
                        throw new Error(`Material not found: ${item.name}`)
                    }
    
                    return {
                        material: mat,
                        qty: item.count
                    }
                })
                const outputMatqty: MaterialQuantities[] = recipe.output.map<MaterialQuantities>(item => {
                    const mat = materials.utils.findMaterialByName(item.name)
                    if (mat === undefined) {
                        throw new Error(`Material not found: ${item.name}`)
                    }
    
                    return {
                        material: mat,
                        qty: item.count
                    }
                })
                
                // Calculate Route Inventory Ratio
                const playerRouteStorage = new Storage(data.playerSpace)
                const truckRouteStorage = new Storage(data.truckSpace)
                const craftingPerRoute = bestInventoryRatio(inputMatqty, playerRouteStorage, truckRouteStorage)

                // Compute Input Material
                inputMatqty.forEach(matqty => recursiveCompute(matqty.material, matqty.qty * craftingNeeded, data, forcedRecipes, result))

                // Remove Input Material from storage
                inputMatqty.forEach(matqty => result.extraMaterials.peekMaterials(matqty.material, matqty.qty * craftingNeeded))

                // Add Output Material to storage
                outputMatqty.forEach(matqty => result.extraMaterials.addMaterials(matqty.material, matqty.qty * craftingNeeded))

                const routeCount = Math.ceil(craftingNeeded / craftingPerRoute)
                result.routes.push({
                    name: recipe.name,
                    count: routeCount,
                    cost: -recipe.cost * craftingPerRoute * routeCount,
                    requireMaterials: inputMatqty.map<MaterialQuantities>(matqty => ({
                        material: matqty.material,
                        qty: matqty.qty * craftingNeeded
                    })),
                    ratio: {
                        player: playerRouteStorage.storage,
                        truck: truckRouteStorage.storage
                    }
                })

                console.log(recipe.name, recipe.cost)
            }
        }
    }

    const compute = (data: FormData, forcedRecipes: { [key: string]: string }): ComputedRoute => {
        const result: ComputedRoute = {
            routes: [],
            extraMaterials: new Storage(-1)
        }
       
        const farmRoute = materials.farmable[data.route]
        if (!farmRoute.sell) 
            return result

        // Required Materials
        const matqty = {
            material: farmRoute,
            qty: Math.ceil(data.money / farmRoute.sell.value)
        }

        // Start Computing
        recursiveCompute(matqty.material, matqty.qty, data, forcedRecipes, result)

        // Remove Materials from storage
        result.extraMaterials.peekMaterials(matqty.material, matqty.qty)

        // Add Selling Route
        const playerRouteStorage = new Storage(data.playerSpace)
        const truckRouteStorage = new Storage(data.truckSpace)
        const craftingPerRoute = bestInventoryRatio([{
            ...matqty,
            qty: 1
        }], playerRouteStorage, truckRouteStorage)
        
        const routeCount = Math.ceil(matqty.qty / craftingPerRoute)
        result.routes.push({
            name: `Selling ${farmRoute.name}`,
            cost: matqty.qty * farmRoute.sell.value,
            count: routeCount,
            requireMaterials: [matqty],
            ratio: {
                player: playerRouteStorage.storage,
                truck: truckRouteStorage.storage
            }
        })

        // Merge Equals Route
        result.routes = mergeEqualRoute(result.routes).reverse()

        return result
    }
    
    const computeItem = (name: string, has: number, truckSpace: number, playerSpace: number): Route | undefined => {      
        const playerStorage = new Storage(playerSpace)
        const truckStorage = new Storage(truckSpace)
        const material = materials.utils.findMaterialByName(name)

        // direct selling
        if (material?.sell) {
            const totalWeight = material.weight * has
            const routeCount = Math.ceil(totalWeight / (playerStorage.maxWeight + truckStorage.maxWeight))

            playerStorage.addMaterials(material, Math.trunc(playerSpace / material.weight))
            truckStorage.addMaterials(material, Math.trunc(truckSpace / material.weight))

            return {
                name: "",
                cost: 0,
                count: routeCount,
                ratio: {
                    player: playerStorage.storage,
                    truck: truckStorage.storage
                },
                requireMaterials: []
            }
        }

        const findRecipe = recipes.sorted.find(rec => rec.input.find(mat => mat.material.name === name) && rec.input.length === 1)
        
        // selling throught one recipe
        if (material && findRecipe) {
            const sellableMat = findRecipe.output.filter(it => materials.farmable.find(mat => mat.name === it.material.name))

            if (sellableMat.length > 0) {
                const maxCraftings = Math.trunc(has / findRecipe.input[0].count)
                const totalWeight = material.weight * findRecipe.input[0].count * maxCraftings
                const routeCount = Math.ceil(totalWeight / (truckSpace + playerSpace))
    
                let profit = 0
                sellableMat.forEach(it => {
                    const mat = materials.utils.findFarmableMaterialByName(it.material.name)

                    if (mat?.sell) {
                        profit += it.count * maxCraftings * mat.sell.value
                    }
                })

                playerStorage.addMaterials(material, Math.trunc(playerSpace / material.weight))
                truckStorage.addMaterials(material, Math.trunc(truckSpace / material.weight))

                return {
                    name: findRecipe.name,
                    cost: findRecipe.cost,
                    count: routeCount,
                    ratio: {
                        player: playerStorage.storage,
                        truck: truckStorage.storage
                    },
                    requireMaterials: []
                }
            }
        }
        
        
        return undefined
    }

    const computeMaterials = (material: Material, forceRecipe: { [key: string]: string }): Material | undefined => {
        if (material.crafting.length > 1 && !forceRecipe[material.name]) {
            return material
        } 

        if (material.crafting.length === 1) {
            const recipe = material.crafting[0]

            for (const it of recipe.input) {
                const mat = materials.utils.findMaterialByName(it.name)

                if (mat) {
                    let res
                    if (res = computeMaterials(mat, forceRecipe)) {
                        return res
                    }
                } else console.warn(`Not find this material: ${it.name}`)
            }
        }
        
        return undefined
    }

    return { compute, computeItem, computeMaterials }
}
import { Navigate, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";

// pages
import { Recipes } from "./pages/recipes";
import { TruckRoute } from "./pages/truckroute";
import { Materials } from "./pages/materials";

// style
import "./app.css"
import { Container } from "react-bootstrap";

function App() {
  return <div className="app" data-bs-theme="dark">
    <Header />
    <Container className="px-0 pb-5">
      <Routes>
        <Route path="/materials" element={<Materials />}></Route>      
        <Route path="/recipes" element={<Recipes />}></Route>
        <Route path="/routes" element={<TruckRoute />}></Route>
        <Route path="*" element={<Navigate to={"/routes"}/>}></Route>
      </Routes>
    </Container>
  </div>
}

export default App;

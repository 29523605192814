import { Material } from "../dataHook"
import { MaterialQuantities } from "../routeCalculatorHooks"

export interface InventoryInstance {
    material: Material
    qty: number
}

export class Storage {

    private _storage: InventoryInstance[]
    private _maxWeight: number
    private _storageWeight: number

    constructor(maxWeight: number) {
        this._storage = []
        this._storageWeight = 0
        this._maxWeight = maxWeight
    }

    private find(name: string): InventoryInstance | undefined {
        return this._storage.find(inst => inst.material.name === name)
    }

    get storage(): InventoryInstance[] {
        return this._storage
    }

    get weight(): number {
        return this._storageWeight
    }

    get maxWeight(): number {
        return this._maxWeight
    }

    get freeSpace(): number {
        return this._maxWeight - this._storageWeight
    }

    clear(): void {
        this._storage = []
        this._storageWeight = 0
    }

    addMaterials(mat: Material, qty: number): void {
        const inst = this.find(mat.name)
        this._storageWeight += mat.weight * qty

        if (inst) {
            inst.qty += qty
        } else {
            this._storage.push({
                material: mat,
                qty: qty
            })
        }
    }

    peekMaterials(mat: Material, maxPeeking: number): number {
        const inst = this.find(mat.name)

        if (inst) {
            const peek = Math.min(inst.qty, maxPeeking)
            inst.qty -= peek

            if (inst.qty === 0) {
                const idx = this._storage.findIndex(stInst => stInst.material.name === inst.material.name)
                this._storage.splice(idx, 1)
            } 

            this._storageWeight -= inst.material.weight * peek
            return peek
        }
        
        return 0
    }
}
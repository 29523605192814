import { Accordion, Badge, Button, Col, Container, Row, Stack } from "react-bootstrap";
import { formatNumberWithDots } from "../utils";

// hooks
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useData } from "../hooks/dataHook";

export function Materials() {

    const location = useLocation()
    const navigate = useNavigate()
    const { materials } = useData()
    const [activeKey, setActivateKey] = useState<string>('')

    useEffect(() => {
        const currentHash = decodeURI(location.hash.slice(1))
        const idx = materials.sorted.findIndex((mat) => mat.name === currentHash)

        if (idx !== -1) setActivateKey(idx.toString())
    }, [location])

    const toggleAccordion = (target: string) => {
        if (activeKey === target) setActivateKey('')
        else setActivateKey(target)
    }

    return <Container className="mt-4">
        <Accordion activeKey={activeKey}>
            { materials.sorted.map((material, idx) => <Accordion.Item key={idx} eventKey={idx.toString()} onClick={() => toggleAccordion(idx.toString())}>
                <Accordion.Header>
                    <div className="w-100 mx-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-start align-items-center" style={{ gap: 6 }}>
                            { material.name }
                            { material.crafting.length === 0 ? <Badge bg="warning text-bg-warning">Raw</Badge> : null }
                        </div>
                        { material.sell ? <Badge bg="primary">Farmable</Badge> : <Badge bg="secondary">Inetrmediate</Badge> }
                    </div>
                </Accordion.Header>
                <Accordion.Body className="py-3 px-5">
                    <Row>
                        <Col>
                            { material.sell ? <>
                                <div className="fw-bold mb-1">Sell Data:</div>
                                <Stack gap={1}>
                                    <div className="ms-3 d-flex justify-content-start align-items-center" style={{ gap: 6 }}>
                                        <Badge bg="secondary" className="opacity-75">Value</Badge>
                                        {`$ ${formatNumberWithDots(material.sell.value)}`}
                                    </div>
                                    <div className="ms-3 d-flex justify-content-start align-items-center" style={{ gap: 6 }}>
                                        <Badge bg="secondary" className="opacity-75">Location</Badge>
                                        { material.sell.location }
                                    </div>
                                </Stack>
                            </> : null }
                        </Col>
                        <Col>
                            <Row className="mb-2">
                                { material.crafting.length > 0 ? <div>
                                        <div className="fw-bold mb-1">Crafting Recipes:</div>
                                        <Stack>
                                            { material.crafting.map((rec, idx) => <div key={idx} className="ms-3"><a href="#">{ rec.name }</a></div>) }
                                        </Stack>
                                    </div> : null }
                            </Row>
                            <Row>
                                { material.usage.length > 0 ? <div>
                                        <div className="fw-bold mb-1">Usage Recipes:</div>
                                        <Stack>
                                            { material.usage.map((rec, idx) => <div key={idx} className="ms-3"><a href="#">{ rec.name }</a></div>) }
                                        </Stack>
                                    </div> : null }
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-4 justify-content-md-end">{ material.sell ? <Button className="col-md-2" onClick={() => navigate("/routes", {
                        state: material.name
                    })} >Farm</Button> : null }</Row>
                </Accordion.Body>
            </Accordion.Item>) }
        </Accordion>
    </Container>
}